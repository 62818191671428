import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";

import EntryIllustration from '../../../../../assets/img/business/pos-hero-illustration.svg'
import EntryIllustrationBlur from "../../../../../assets/img/business/blur/hard-pos-hero-blur.svg"
import KudaPOSTerminalIcon from "../../../../../assets/img/Pos-terminal.inline.svg"
import InvoiceIcon from "../../../../../assets/img/kuda-invoice.inline.svg"
import SendGreyIcon from "../../../../../assets/img/kuda-send-grey.inline.svg"

import flexiblePaymentImage from "../../../../../assets/img/business/hard_pos/flexiblePayment-illustration.svg"
import posTransactionImage from "../../../../../assets/img/business/hard_pos/posTransaction-illustration.svg"
import ussdPaymentImage from "../../../../../assets/img/business/hard_pos/ussdPayment-illustration.svg"
import posOptionsImage from "../../../../../assets/img/business/hard_pos/posOptions-illustration.svg"

import InvoicingIcon from "../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import SoftPOSIcon from '.././../../../../assets/img/more-for-you/kuda-softPos-icon.inline.svg'
import VirtualPOSIcon from '../../../../../assets/img/more-for-you/kuda-virtual-icon.inline.svg'

import { Img } from "react-image";

const entryContent = {
  title: (
    <span>
      Get a Kuda <span className="color-secondary">POS machine</span> with flexible payment options!
    </span>
  ),
  subtitle: "The Kuda POS machine gives your customers more convenient ways to pay you quickly.",
  name: "Join Kuda Business",
  url: 'https://kudabusiness.onelink.me/epwz/jceed731/',
}

const businessTopFeatures = [
  {
    icon: <KudaPOSTerminalIcon />,
    text: "Buy a POS machine or lease it for a one-time fee."
  },
  {
    icon: <InvoiceIcon />,
    text: "See your POS transactions in real-time."
  },
  {
    icon: <SendGreyIcon />,
    text: "Receive card payments, transfers and USSD payments."
  },
]

const flexiblePayment = {
  title: "Enjoy flexible payment options for a POS machine.",
  subtitle: "For your convenience, you can either buy a POS machine outright or lease one for a one-time fee.",
  url: 'https://kudabusiness.onelink.me/epwz/jceed731/',
  name: "Join Kuda Business",
  illustration: (
    <Img src={flexiblePaymentImage} className="kuda-business-image" />

  ),
}

const PosTransactions = {
  title: "See your POS transactions in real-time.",
  subtitle: "Our detailed yet simple dashboard makes it easy for you to keep track of all POS payments and know how your business is doing.",
  url: 'https://kudabusiness.onelink.me/epwz/jceed731/',
  name: "Join Kuda Business",
  illustration: (
    <Img src={posTransactionImage} className="kuda-business-image" />
  ),
}

const UssdPayments = {
  title: "Receive card payments, transfers and USSD payments.",
  subtitle: "With a Kuda POS machine, your customers can pay you quickly by card, transfer or USSD at your business location.",
  url: 'https://kudabusiness.onelink.me/epwz/jceed731/',
  name: "Join Kuda Business",
  illustration: (
    <Img src={ussdPaymentImage} className="kuda-business-image" />
  ),
}

const PosOptions = {
  title: "Explore our virtual POS option.",
  subtitle: "Get all the benefits of a physical POS without the cost of buying or leasing a device.",
  url: 'https://kudabusiness.onelink.me/epwz/jceed731/',
  name: "Join Kuda Business",
  illustration: (
    <Img src={posOptionsImage} className="kuda-business-image" />
  ),
}

const questions = [
  {
    heading: "What’s a POS?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16 mb-3">A POS (or point-of-sale terminal/device/machine) is used by merchants to process payments made by their customers.</span>
        <span className="faq-description f-16">A POS can be physical or virtual, and it can accept contact and contactless payments.</span>
      </span>),
    index: 1
  },
  {
    heading: "What’s the meaning of POS?",

    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">POS means point-of-sale.</span>
      </span>),

    index: 2
  },
  {
    heading: "How do I get a Kuda POS?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">Request a POS <a
          href="https://kudabusiness.onelink.me/epwz/jceed731/"
          target="_blank"
          rel="noopener noreferrer"
          className="color-black link-decoration cursor-pointer ml-1"
        >here</a> and we’ll get back to you as quickly as possible.
        </span>

      </div>),
    index: 3
  },
  {
    heading: "How much does a Kuda POS machine cost?",

    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">There are two payment options for a Kuda POS machine:</span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. You can buy the POS machine outright.

              <span className="flex mt-4 line-height-2">Details: For a one time payment of ₦80,000 (minus delivery costs), we’ll send you a Kuda POS machine. This cost doesn’t include a delivery fee, which varies depending on your delivery address. You can also choose to pick up the POS machine at 1-11 Commercial Avenue, Sabo, Yaba, Lagos.</span>
              <span className="flex mt-4 line-height-2 ">Once you buy the POS machine, it becomes your property. The only subsequent cost is a merchant service commission of 0.5% per POS transaction. This commission is capped at ₦1,000 — you’ll never pay more than that.</span>
            </div>
            <div className="mb-3">2. You can lease a POS machine.

              <span className="flex mt-4 line-height-2">
                Details: If your business is able to make at least 15 card transactions daily, you can lease a POS machine from us for a one-time fee of ₦15,000. This cost doesn’t include a delivery fee, which varies depending on your delivery address. You can also choose to pick up the terminal.
                If your business makes up to ₦10 million in sales revenue within three (3) months of leasing a POS machine, we’ll refund the ₦15,000 leasing fee and the machine will become your property permanently.
              </span>

              <span className="flex mt-4 line-height-2">
                Please, note that when you lease a POS machine from us, you’ll pay a merchant service commission of 0.5% on each card transaction made on the terminal. This merchant service commission is capped at ₦1,000 — you’ll never pay more than that.
              </span>

              <span className="flex mt-4 line-height-2">
                We reserve the right to take back a leased POS machine if the machine isn’t active or your business doesn’t meet the target of 15 daily card transactions.
              </span>
            </div>

          </div>
        </span>

      </span>),

    index: 4
  },

  {
    heading: "Is the Kuda POS machine secure for transactions?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">Yes, it’s secure. The Kuda POS machine uses the highest security standards to make contact and contactless transactions secure.
        </span>

      </div>),
    index: 5
  },
  {
    heading: "What features does the Kuda POS machine have?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          The features of the Kuda POS machine include:
        </span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3 line-height-2">1. Quick contact and contactless payments - card, transfers and USSD. </div>
            <div className="mb-3 line-height-2">2. A transaction dashboard on your Kuda Business app to monitor POS payments as they are made.</div>
            <div className="mb-3 line-height-2">3. Instant transaction alerts.</div>
            <div className="mb-3 line-height-2">4. Quick settlements for merchants and POS businesses.</div>
          </div>
        </span>

      </div>),
    index: 6
  },
  {
    heading: "What kinds of businesses can use the Kuda POS machine?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">Merchants, POS businesses (agent banking), freelancers and all other kinds of businesses can use the Kuda POS machine.</span>

      </div>),
    index: 7
  },
]

const moreForYou = [

  {
    icon: <InvoicingIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <SoftPOSIcon />,
    title: "softPOS",
    subText: `Turn your smartphone into a POS and give your customers more ways to pay you.`,
    linkTo: `/en-ng/business/softpos-payments/`
  },
  {
    icon: <VirtualPOSIcon />,
    title: "Virtual POS",
    subText: `Empower your salespeople to accept payments instantly at all your business locations.`,
    linkTo: `/en-ng/business/virtual-pos/`
  },

]
const BusinessHardPOS = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img className="hero-illustration" src={EntryIllustrationBlur}/>}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={flexiblePayment.title}
        subtitle={flexiblePayment.subtitle}
        illustration={flexiblePayment.illustration}
        name={flexiblePayment.name}
        url={flexiblePayment.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={PosTransactions.title}
        subtitle={PosTransactions.subtitle}
        illustration={PosTransactions.illustration}
        name={PosTransactions.name}
        url={PosTransactions.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={UssdPayments.title}
        subtitle={UssdPayments.subtitle}
        illustration={UssdPayments.illustration}
        name={UssdPayments.name}
        url={UssdPayments.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={PosOptions.title}
        subtitle={PosOptions.subtitle}
        illustration={PosOptions.illustration}
        name={PosOptions.name}
        url={PosOptions.url}
        isExternal={true}
      />
      <FAQList title={"POS FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou}/>
      <CTABusiness />
    </Fragment>
  )
}

export default BusinessHardPOS
