import styled from "styled-components";

export const HeroHeaderStyle = styled.div`

.business-centered-header{
    min-height: 80vh;
}
.hero-illustration {
    display: block;
    max-width: 700px;
    width: auto;
    margin: auto;
    transition: all .7s ease-in-out;
}

@media only screen and (min-width: 768px){
    .hero-illustration {
        max-width: 100%;
    }
   
}

@media only screen and (max-width: 991px){
    .business-home-fold, .home-fold{
        display: inline-table;
        min-height: 570px;
    }
}

@media screen and (max-width: 768px) {
    .hero-illustration {
    max-width: 100%;
    }

    .business-centered-header{
        min-height: 60vh !important;
    }
}

@media only screen and (max-width: 375px) {

    .business-centered-header {
        padding-left: 14px;
        padding-right: 14px;
    }
}

@media screen and (max-width: 320px) {

    .hero-illustration {
        width: 280px !important;
    }

}
`

export const HomeHeaderFoldStyled = styled.div`

.business-header-title {
    font-weight: 900;
    font-size: 48px;
    line-height: 50px;
    margin-bottom: 36px;
    max-width: 850px;
}


.business-header-subtext {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    max-width: 650px;
    margin-bottom: 36px;
}

.dark-cta {  
   line-height: 1;
    height: auto;
    padding: 24px;
    transition: transform 0.6s;
    cursor: pointer;
}

.kuda-business-illustration--center {
    display: flex;
    justify-content: center;

    img {
        max-width: 526px;
        max-height: 523px;
        width: auto;
    }
}

@media only screen and (max-width: 991px) {

    .kuda-section--business-illustration img {
        width: 100%;
        max-width: 426px;
        margin-left: auto;
        margin-right: auto;
        height: auto !important;
        margin-top: 50px;
    }

    .business-header-title {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .business-home-fold, .home-fold{
        display: inline-table;
        min-height: 570px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    img.kuda--business-illustration {
        transform: scale(0.7);
    }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .kuda--business-illustration {
        transform: scale(0.8);
    }

    .career-container-width {
        flex-direction: row;
    } 
}

@media only screen and (max-width: 768px){
    a.dark-cta.cta_button {
        padding: 16px 24px;
        font-size: 14px;
        width: max-content;
    }
}

@media only screen and (max-width: 575px) {
    .kuda-section--business-illustration img {
        width: 100%;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        height: auto !important;
        margin-top: 50px;
    }
}
@media only screen and (max-width: 375px) {

    .business-text-wrap {
        padding-left: 14px;
        padding-right: 14px;
    }
}
`

export const ImageTextLeftWithButtonStyle = styled.div`

.kuda-section {
    margin-top: 12.5%;
}

.kuda-section--wrap {
    max-width: 423px;
}
.kuda-text--right .kuda-section--wrap {
    margin-left: 0px;
    margin-right: 0px;
}

.kuda-section--max {
    max-width: 398px;

    .trans_button{
        background: none;
        border: none;
        color: black;
        text-decoration: underline;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.kuda-section--illustration svg.transform-right {
    transform: scale(1.2) !important;
}

.kuda-section--illustration .transform {
    transform: scale(1.12) !important;
}

.kuda-section--illustration svg,
.kuda-section--illustration img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 425px){
   .kuda-section--max{
    max-width: 230px;
   }

   .fit-content{
    max-width: fit-content;
   }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {

    .kuda-section--illustration svg,
    .kuda-section--illustration img {
        width: 360px !important;

    }

    .kuda-section--wrap {
        max-width: 368px;
    }

    .kuda-section--max {
        max-width: 360px;
    }

    .kuda-cta-dark-section .biz-float--bottom img {
        max-width: 380px;
    }
}

@media only screen and (max-width: 991px) {
    .kuda-section--wrap {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .kuda-section--max {
        justify-content: center;
    }

    .kuda-section--illustration svg,
    .kuda-section--illustration img {
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        height: auto !important;
        margin-top: 50px;
    }

}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .kuda-section--illustration>[aria-label="animation"] {
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
    }
    .kuda-section {
        margin-top: 45px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (max-height: 698px) {
    .kuda-section--illustration svg,
    .kuda-section--illustration img {
        max-width: 360px;
    }
}
`

export const ImageTextRightWithButtonStyle = styled.div`
.kuda-section {
    margin-top: 12.5%;
}

.kuda-section--wrap {
    max-width: 423px;
}
.kuda-text--right .kuda-section--wrap {
    /* margin-left: auto; */
    margin-right: auto;
}

.kuda-section--max {
    max-width: 398px;

    .trans_button{
        background: none;
        border: none;
        color: black;
        text-decoration: underline;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.kuda-section--illustration svg.transform-right {
    transform: scale(1.2) !important;
}

.kuda-section--illustration .transform {
    transform: scale(1.12) !important;
}

.kuda-section--illustration svg,
.kuda-section--illustration img {
    display: block;
    // margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 425px){
    .kuda-section--max{
     max-width: 230px;
    }

    .fit-content{
        max-width: fit-content;
       }
 }

 @media only screen and (min-width: 992px) and (max-width: 1024px) {
 
     .kuda-section--illustration svg,
     .kuda-section--illustration img {
         width: 360px !important;
     }
 
     .kuda-section--wrap {
         max-width: 368px;
     }
 
     .kuda-cta-dark-section .biz-float--bottom img {
         max-width: 380px;
     }
 }
 

 @media only screen and (max-width: 991px) {

     .kuda-section--wrap {
         text-align: center;
         margin-left: auto;
         margin-right: auto;
     }
 
     .kuda-section--max {
         justify-content: center;
     }
 
     .kuda-section--illustration svg,
     .kuda-section--illustration img {
         width: 100%;
         max-width: 300px;
         margin-left: auto;
         margin-right: auto;
         height: auto !important;
         margin-top: 50px;
     }
 
 }
 
 @media only screen and (min-width: 481px) and (max-width: 767px) {
     .kuda-section--illustration>[aria-label="animation"] {
         max-width: 420px;
         margin-left: auto;
         margin-right: auto;
     }
     .kuda-section {
         margin-top: 45px;
     }
 }
 
@media only screen and (min-width: 768px) and (max-width: 1280px) and (max-height: 698px) {
     .kuda-section--illustration svg,
     .kuda-section--illustration img {
         max-width: 360px;
    }
}

`

export const CenteredHeroHeaderStyle = styled.div`

.business-centered-header{
    min-height: 0;
}
.hero-illustration {
    display: block;
    max-width: 700px;
    width: auto;
    margin: auto;
    transition: all .7s ease-in-out;
}

@media only screen and (min-width: 768px){
    .hero-illustration {
        max-width: 100%;
    }
   
}

@media only screen and (max-width: 991px){
    .business-home-fold, .home-fold{
        display: inline-table;
        min-height: 570px;
    }
}

@media screen and (max-width: 768px) {
    .hero-illustration {
    max-width: 100%;
    }

    
}

@media only screen and (max-width: 375px) {

    .business-centered-header {
        padding-left: 14px;
        padding-right: 14px;
    }
}

@media screen and (max-width: 320px) {

    .hero-illustration {
        width: 280px !important;
    }

}
`